import {Routes} from '@angular/router';
import {loginRedirect, notFoundRedirect} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...loginRedirect('pro'),
  {
    path: 'pro/login',
    loadChildren: () => import('../main-pages/login-check/login-check.routes'),
  },
  {
    path: 'pro/pdf/download',
    loadChildren: () => import('../main-pages/pdf-download/pdf-download.routes'),
  },
  {
    path: 'pro/dashboard',
    loadChildren: () => import('../main-pages/dashboard/dashboard.routes'),
  },
  {
    path: 'pro/checkout',
    loadChildren: () => import('../main-pages/checkout/checkout.routes'),
  },
  {
    path: 'pro/trades',
    loadChildren: () => import('../main-pages/trades/trades.routes'),
  },
  {
    path: 'pro/trade/details/:id',
    loadChildren: () => import('../main-pages/trade-details/trade-details.routes'),
  },
  {
    path: 'pro/reports/:reportName',
    loadChildren: () => import('../main-pages/reports/reports.routes'),
  },
  {
    path: 'pro/scanproducts',
    loadChildren: () => import('../main-pages/scan-products/scan-products.routes'),
  },
  ...notFoundRedirect('pro'),
];
