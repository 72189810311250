import {Injectable} from '@angular/core';
import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {TRADE_IN_ADMIN_MANGER_ROLES} from '@common/globals/tradeInAdminMangerRoles';
import {EnvUrl} from '@common/statics/getEnvURL';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {RouteCommon} from '@common/ts/routeCommon';
import {TradeURLs} from '@common/ts/tradeInterfaces';

@Injectable()
export class MenuRoutesService extends RouteCommon<TradeURLs> {

  dashboardRoute(): void {
    if (UserRoles.hasRole(TRADE_IN_ADMIN_MANGER_ROLES)) {
      this.menuRoutes('pro/dashboard');
    } else if (AppModules.hasItemLocationModule(ModuleTypes.TradeIn)) {
      this.menuRoutes('pro/scanproducts');
    } else {
      window.location.assign(`${EnvUrl.getEnvURL('Ecommerce')}/login`);
    }
  }

}
